import axios from 'axios';

import { USER } from '../../constants/defaultValues';
import { setErrorAction } from '../../helpers/Utils';
import {
  ACTION_USER,
  CREATE_USER,
  ERROR_CREATE_USER,
  ACTION_FETCH_USER,
  FETCH_USER,
  ERROR_FETCH_USER,
  CLEAR_USER,
  UPDATE_USER,
  ERROR_UPDATE_USER,
  CLEAR_ALERT_USER,
  FETCH_USERS,
  ERROR_FETCH_USERS,
  ACTION_FETCH_USERS,
  DELETE_USER,
  ERROR_DELETE_USER,
  ACTIVE_USER,
  ERROR_ACTIVE_USER,
  WELCOME_USER,
  ERROR_WELCOME_USER,
  ACTION_DATA_FIELDS_USER,
  DATA_FIELDS_USER,
  ERROR_DATA_FIELDS_USER,
  CHANGE_PASSWORD_USER,
  ERROR_CHANGE_PASSWORD_USER,
  CHANGE_EMAIL_PASSWORD_USER,
  ERROR_CHANGE_EMAIL_PASSWORD_USER,
  ACCEPT_FIRST_LOGIN_USER,
  ERROR_ACCEPT_FIRST_LOGIN_USER,
  SET_CURRENT_PAGE_USER,
  SET_SELECTED_PAGE_SIZE_USER,
  SET_SORT_USER,
  SET_SEARCH_USER,
  SET_SEARCH_VALUE_USER,
} from './types';

import {
  LOGIN_AUTHOR_SUCCESS,
  FIRST_LOGIN_AUTHOR
} from '../auth/types';

import { HEADERS } from '../../constants/defaultValues';

export const firstLogin = () => ({
  type: FIRST_LOGIN_AUTHOR,
});

/****************************    START ACTION USER   ******************************************/

export const actionUser = (user, history) => ({
  type: ACTION_USER,
  payload: { user, history }
});

export const actionFetchUser = (user, history) => ({
  type: ACTION_FETCH_USER,
  payload: { user, history }
});

export const actionFetchUsers = (history) => ({
  type: ACTION_FETCH_USERS,
  payload: { history }
});

export const actionFetchDataFields = () => ({
  type: ACTION_DATA_FIELDS_USER
});

/****************************    END ACTION USER   ******************************************/

/****************************    START CREATE USER   ******************************************/

export const createUserSuccess = (user) => ({
  type: CREATE_USER,
  payload: user
});
export const createUserError = (message) => ({
  type: ERROR_CREATE_USER,
  payload: { message }
});

export const startCreateUser = (user, history, locale, token, form) => {
  const headers = HEADERS;
  return dispatch => {
    dispatch(actionUser(user, history))
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.post(`${USER}/store`, user, { headers })
      .then(res => {
        dispatch(createUserSuccess(res.data))
        form.resetForm()
        form.setStatus({ success: true })
      })
      .catch(error => {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
          dispatch(createUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(createUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END CREATE USER   ******************************************/

/****************************    START FETCH USER   ******************************************/

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER,
  payload: user
});
export const fetchUserError = (message) => ({
  type: ERROR_FETCH_USER,
  payload: { message }
});

export const startFetchUser = (user, history, locale, token) => {
  const headers = HEADERS;
  return dispatch => {
    dispatch(actionFetchUser(user, history))
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.get(`${USER}/edit/${user}`, { headers })
      .then(res => {
        const data = res.data;

        const results = {
          edit: true,
          id: data.id,
          name: data.name,
          surname: data.surname,
          phone_iso: data.phone_iso,
          phone_code: data.phone.split(' ')[0],
          mobile_iso: data.mobile_iso,
          phone: data.phone.split(' ')[1],
          mobile: data.mobile.split(' ')[1],
          mobile_code: data.mobile.split(' ')[0],
          email: data.email,
          viber: data.viber ? data.viber : "",
          whatsapp: data.whatsapp ? data.whatsapp : "",
          skype: data.skype ? data.skype : "",
          buisness_id: data.buisness_id,
          gender_id: data.gender_id,
          job_title_id: data.job_title_id,
          role_id: data.role_id[0].id,
          isOnline: data.isOnline,
          image: data.image ? data.image : '',
          file: ''
        };

        dispatch(fetchUserSuccess(results))
      })
      .catch(error => {
        history.push('/error');
        try {
          dispatch(fetchUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(fetchUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END FETCH USER   ******************************************/

/****************************    START UPDATE USER   ******************************************/

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER,
  payload: user
});
export const updateUserError = (message) => ({
  type: ERROR_UPDATE_USER,
  payload: { message }
});

export const updateUserValues = (author) => ({
  type: LOGIN_AUTHOR_SUCCESS,
  payload: author
});

export const startUpdateUser = (author, id, user, history, locale, token, form) => {
  const headers = HEADERS;
  return dispatch => {
    dispatch(actionUser(user, history))
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.post(`${USER}/update/${id}`, user, { headers })
      .then(res => {
        dispatch(updateUserSuccess(res.data.status))
        if (parseInt(author) === parseInt(id)) {
          dispatch(updateUserValues(res.data.user))
        }
        form.setStatus({ success: true })
      })
      .catch(error => {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
          dispatch(updateUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(updateUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END UPDATE USER   ******************************************/

/****************************    START CLEAR STATES USER   ******************************************/
export const clearUser = () => ({
  type: CLEAR_USER
});
export const clearAlertUser = () => ({
  type: CLEAR_ALERT_USER
});
/****************************    END CLEAR STATES USER   ******************************************/

/****************************    START FETCH USERS   ******************************************/

export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS,
  payload: users
});
export const fetchUsersError = (message) => ({
  type: ERROR_FETCH_USERS,
  payload: { message }
});

export const startFetchUsers = (history, locale, token, filter) => {
  const headers = HEADERS;
  return dispatch => {
    dispatch(actionFetchUsers(history))
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    let dataField = "";
    let order = "";

    if (filter.sortObj) {
      dataField = filter.sortObj.dataField;
      order = filter.sortObj.order;
    }

    axios.get(`${USER}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}&dataField=${dataField}&order=${order}&search=${filter.search}`, { headers })
      .then(res => {
        dispatch(fetchUsersSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(fetchUsersError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(fetchUsersError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END FETCH USERS   ******************************************/

/****************************    START DELETE   ******************************************/

export const deleteUserSuccess = (deleteUser) => ({
  type: DELETE_USER,
  payload: deleteUser
});
export const deleteUserError = (message) => ({
  type: ERROR_DELETE_USER,
  payload: { message }
});

export const startDeleteUser = (locale, token, id) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.delete(`${USER}/destroy/${id}`, { headers })
      .then(res => {
        dispatch(deleteUserSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(deleteUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(deleteUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END DELETE   ******************************************/

/****************************    START ACTIVATE   ******************************************/
export const activeUserSuccess = (activeUser) => ({
  type: ACTIVE_USER,
  payload: activeUser
});
export const activeUserError = (message) => ({
  type: ERROR_ACTIVE_USER,
  payload: { message }
});

export const startActiveUser = (locale, token, id, action) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.put(`${USER}/active/${id}`, { action: action }, { headers })
      .then(res => {
        dispatch(activeUserSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(activeUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(activeUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};
/****************************    END ACTIVATE   ******************************************/

/****************************    START DELETE   ******************************************/

export const welcomeUserSuccess = (welcomeUser) => ({
  type: WELCOME_USER,
  payload: welcomeUser
});
export const welcomeUserError = (message) => ({
  type: ERROR_WELCOME_USER,
  payload: { message }
});

export const startWelcomeUser = (locale, token, id) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.get(`${USER}/welcome/${id}`, { headers })
      .then(res => {
        dispatch(welcomeUserSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(welcomeUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(welcomeUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END DELETE   ******************************************/

/****************************    START CHANGE PASSWORD   ******************************************/
export const changePasswordUserSuccess = (user) => ({
  type: CHANGE_PASSWORD_USER,
  payload: user
});
export const changePasswordUserError = (message) => ({
  type: ERROR_CHANGE_PASSWORD_USER,
  payload: { message }
});

export const startChangePassword = (id, data, locale, token) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;
    dispatch(actionUser("", ""))

    axios.put(`${USER}/changePassword/${id}`, data, { headers })
      .then(res => {
        dispatch(changePasswordUserSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(changePasswordUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(changePasswordUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};
/****************************    END CHANGE PASSWORD   ******************************************/

/****************************    START CHANGE EMAIL PASSWORD   ******************************************/
export const changeEmailPasswordUserSuccess = (user) => ({
  type: CHANGE_EMAIL_PASSWORD_USER,
  payload: user
});
export const changeEmailPasswordUserError = (message) => ({
  type: ERROR_CHANGE_EMAIL_PASSWORD_USER,
  payload: { message }
});

export const startChangeEmailPassword = (id, data, locale, token) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;
    dispatch(actionUser("", ""))

    axios.put(`${USER}/changeEmailPassword/${id}`, data, { headers })
      .then(res => {
        dispatch(changeEmailPasswordUserSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(changeEmailPasswordUserError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(changeEmailPasswordUserError('Something went wrong. Please try again later.'))
        }
      });
  }
};
/****************************    END CHANGE EMAIL PASSWORD   ******************************************/


/****************************    START FETCH DATA FIELDS   ******************************************/

export const dataFields = (dataFields) => ({
  type: DATA_FIELDS_USER,
  payload: dataFields
});
export const dataFieldsError = (message) => ({
  type: ERROR_DATA_FIELDS_USER,
  payload: { message }
});

export const startFetchDataFields = (locale, token, ifEdit) => {
  const headers = HEADERS;
  return async dispatch => {
    dispatch(actionFetchDataFields())
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    const res = await axios.get(`${USER}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });
    try {
      dispatch(dataFields(res.data));
      return true;
    } catch (error) {
      try {
        dispatch(dataFieldsError(setErrorAction(error.response.data.errors)))
      } catch (error) {
        dispatch(dataFieldsError('Something went wrong. Please try again later.'))
      }
      return false;
    }
  }
};

/****************************    START FETCH DATA FIELDS    ******************************************/

/****************************    START ACCEPT FIRST LOGIN   ******************************************/
export const acceptFirstLogin = (acceptFirstLogin) => ({
  type: ACCEPT_FIRST_LOGIN_USER,
  payload: acceptFirstLogin
});
export const acceptFirstLoginError = (message) => ({
  type: ERROR_ACCEPT_FIRST_LOGIN_USER,
  payload: { message }
});

export const startAcceptFirstLogin = (locale, token, hideModal) => {
  const headers = HEADERS;
  return async dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    const res = await axios.get(`${USER}/acceptFirstLogin`, { headers });
    try {
      dispatch(acceptFirstLogin(res.data));
      dispatch(firstLogin());
      hideModal(true)
    } catch (error) {
      try {
        dispatch(acceptFirstLoginError(setErrorAction(error.response.data.errors)))
      } catch (error) {
        dispatch(acceptFirstLoginError('Something went wrong. Please try again later.'))
      }
    }
  }
};

/****************************    END ACCEPT FIRST LOGIN    ******************************************/

/****************************    START LIST HEADING SETTINGS   ******************************************/

export const setCurrentPage = (currentPage) => {
  return async dispatch => {
    await dispatch({ type: SET_CURRENT_PAGE_USER, payload: currentPage })

    return true;
  }
};

export const setSort = (sortObj) => {
  return async dispatch => {
    await dispatch({ type: SET_SORT_USER, payload: sortObj })

    return true;
  }
};

export const setSelectedPageSize = (size) => ({
  type: SET_SELECTED_PAGE_SIZE_USER,
  payload: size
});

export const setSearch = (keaywords) => {
  return async dispatch => {
    await dispatch({ type: SET_SEARCH_USER, payload: keaywords })

    return true;
  }
};

export const setSearchValue = (keaywords) => ({
  type: SET_SEARCH_VALUE_USER,
  payload: keaywords
});

/****************************    END LIST HEADING SETTINGS   ******************************************/









