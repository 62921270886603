import React, {useState} from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../redux/layout/actions";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/myds-logo-dark.svg";
import logoLight from "../../assets/images/myds-logo-dark.svg";
import logoDark from "../../assets/images/myds-logo.svg";

//i18n
import { withTranslation } from "react-i18next";

// Reactstrap
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";

import packageJson from '../../../package.json';
import { useClearCacheCtx } from 'react-clear-cache';

import { startAcceptFirstLogin } from "../../redux/users/actions";
import { startRefreshToken } from "../../redux/auth/actions";
import jwt from 'jsonwebtoken';
import { jwt_secret } from '../../constants/defaultValues';

const Header = props => {
  const [firstLogin, setFirstLogin] = useState(props.author.user.first_login);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  
  const ClearCache = () => {
    if (!isLatestVersion) {
      setModal(true)
    }
    return null
  };

  const checkFirstLogin = () => {
    const {
        startRefreshToken,
        history,
        i18n,
        author,
        startAcceptFirstLogin,
    } = props;

    jwt.verify(author.access_token, jwt_secret, (err, decoded) => {
        if (err) {
          startRefreshToken(author.access_token, history).then(res => {
            startAcceptFirstLogin(i18n.language, res.access_token, setFirstLogin);
          });
        } else {
          startAcceptFirstLogin(i18n.language, author.access_token, setFirstLogin);
        }
    });
  }


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  return (
    <React.Fragment>
      <ClearCache />
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="17" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="19" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-toggle="collapse"
            onClick={() => {
              props.toggleLeftmenu(!props.leftMenu);
            }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          {/* <form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <span className="uil-search"></span>
            </div>
          </form> */}
        </div>

        <div className="d-flex">
          {/* <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <i className="uil-search"></i>
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("Search") + "..."}
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> */}

          <LanguageDropdown />

          {/* <Dropdown
            className="d-none d-lg-inline-block ms-1"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              caret
              tag="button"
            >
              <i className="uil-apps" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" right>
              <div className="px-lg-2">
                <Row className="g-0">
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={github} alt="Github" />
                      <span>GitHub</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={bitbucket} alt="bitbucket" />
                      <span>Bitbucket</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={dribbble} alt="dribbble" />
                      <span>Dribbble</span>
                    </Link>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={dropbox} alt="dropbox" />
                      <span>Dropbox</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={mail_chimp} alt="mail_chimp" />
                      <span>Mail Chimp</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={slack} alt="slack" />
                      <span>Slack</span>
                    </Link>
                  </Col>
                </Row>
              </div>
            </DropdownMenu>
          </Dropdown> */}

          <div className="dropdown d-none d-lg-inline-block ms-1">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              onClick={() => {
                toggleFullscreen();
              }}
              data-toggle="fullscreen"
            >
              <i className="uil-minus-path"></i>
            </button>
          </div>

          <NotificationDropdown />

          <ProfileMenu />

          <div className="dropdown d-inline-block">
            <button
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <i className="uil-cog"></i>
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={firstLogin ? false : true}
        scrollable
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader><i className="glyph-icon simple-icon-info text-primary" style={{position: 'relative', top: 5, fontSize: 24}}/> {props.i18n.language === "en" ? "ERP Access & Usage Terms" : "Όροι πρόσβασης & χρήσης του ERP"}</ModalHeader>
        <ModalBody style={{maxHeight: 500}}>
          {props.i18n.language === "en" ?
          <div>
            <h5 className='text-primary font-weight-bold'><b>Welcome to the DS Freight Forwarding IKE ERP System</b></h5>
            <p>By entering the system, you accept the following terms without any reservation:</p>
            <ol>
              <li>
                <b className='text-primary font-weight-bold'>Data and Order Accuracy</b>
                <p>
                  You are fully responsible for the accuracy of the order, the information and the data you enter into the system. By submitting and saving information, you confirm that you have verified its correctness and that the data corresponds to the actual loading and booking details relevant to your tasks.
                </p>
              </li>
              <li>
                <b className='text-primary font-weight-bold'>Acceptance of Data Integrity</b>
                <p>
                  You confirm and accept the accuracy of the data you enter and take full responsibility for any discrepancies or errors that may arise from incorrect or incomplete information.
                </p>
              </li>
              <li>
                <b className='text-primary font-weight-bold'>Security Policy</b>
                <p>
                  Access to the system is granted via personal security codes. Maintaining the confidentiality of these codes is your personal responsibility. These codes should be used exclusively by you, and if any suspicious or unauthorized access occurs, you must immediately inform the company.
                </p>
              </li>
              <li>
                <b className='text-primary font-weight-bold'>Data Protection Policy</b>
                <p>
                  All data entries are subject to our company’s data protection policy, which complies with European data protection regulations (GDPR). The data you enter will be managed with complete confidentiality and used solely for company purposes.
                </p>
              </li>
            </ol>
            <p>
              By clicking <b className='text-primary font-weight-bold'>"Accept,"</b> you confirm that you fully understand and accept the above terms and that you will use the system in compliance with our company's security and data protection policies.
            </p>
          </div>          
          : 
          <div>
            <h5 className='text-primary font-weight-bold'><b>Καλωσήρθατε στο Σύστημα ERP της DS Freight Forwarding IKE</b></h5>
            <p>Με την είσοδό σας στο σύστημα, αποδέχεστε τους παρακάτω όρους χωρίς καμία επιφύλαξη:</p>
            <ol>
              <li>
                <b className='text-primary font-weight-bold'>Ακρίβεια Στοιχείων και εντολών</b>
                <p>
                  Είστε πλήρως υπεύθυνοι για την ακρίβεια των εντολών, των στοιχείων και των δεδομένων που καταχωρείτε στο σύστημα. 
                  Με την καταχώρηση και αποθήκευση των πληροφοριών, δηλώνετε ότι έχετε ελέγξει την ορθότητά τους και ότι τα δεδομένα ανταποκρίνονται 
                  στις πραγματικές συνθήκες φόρτωσης και κράτησης που αφορούν τις εργασίες σας.
                </p>
              </li>
              <li>
                <b className='text-primary font-weight-bold'>Αποδοχή Ορθότητας</b>
                <p>
                  Επιβεβαιώνετε και αποδέχεστε την ακρίβεια των στοιχείων που καταχωρείτε και αναλαμβάνετε πλήρη ευθύνη για τυχόν ασυνέπειες ή 
                  σφάλματα που μπορεί να προκύψουν λόγω λανθασμένων ή ελλιπών πληροφοριών.
                </p>
              </li>
              <li>
                <b className='text-primary font-weight-bold'>Πολιτική Ασφαλείας</b>
                <p>
                  Η πρόσβαση στο σύστημα γίνεται μέσω προσωπικών κωδικών ασφαλείας. Η διατήρηση της εμπιστευτικότητας αυτών των κωδικών αποτελεί 
                  προσωπική σας ευθύνη. Η χρήση των κωδικών σας θα πρέπει να πραγματοποιείται αποκλειστικά από εσάς, και σε περίπτωση ύποπτης ή 
                  μη εξουσιοδοτημένης πρόσβασης, θα πρέπει να ενημερώσετε άμεσα την εταιρεία.
                </p>
              </li>
              <li>
                <b className='text-primary font-weight-bold'>Πολιτική Προστασίας Δεδομένων</b>
                <p>
                  Όλες οι καταχωρήσεις δεδομένων υπόκεινται στην πολιτική προστασίας δεδομένων της εταιρείας μας, που είναι σύμφωνη με τους 
                  ευρωπαϊκούς κανονισμούς προστασίας προσωπικών δεδομένων (GDPR). Τα δεδομένα που καταχωρείτε θα διαχειρίζονται με απόλυτη 
                  εμπιστευτικότητα και αποκλειστικά για τους σκοπούς της εταιρείας.
                </p>
              </li>
            </ol>
            <p>
              Πατώντας <b className='text-primary font-weight-bold'>"Αποδοχή"</b> δηλώνετε ότι κατανοείτε και αποδέχεστε πλήρως τους παραπάνω όρους και ότι θα χρησιμοποιείτε το σύστημα με βάση τις 
              πολιτικές ασφάλειας και προστασίας δεδομένων της εταιρείας μας.
            </p>
          </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button 
          color="primary" 
          className={`btn-shadow btn-multiple-state btn-rounded ps-4 pe-4 me-2 mb-2 ${loading ? "show-spinner" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            setLoading(true);
            setTimeout(() => {
              checkFirstLogin();
            }, 1000);
          }}>
            <span className="spinner">
                <Spinner type="grow" color="light" />
                <Spinner type="grow" color="light" />
                <Spinner type="grow" color="light" />
            </span>
            <span className="label">{props.i18n.language === "en" ? "Accept" : "Αποδοχή"}</span>
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop={"static"}
          centered={true}
        >
          <ModalHeader><i className="glyph-icon simple-icon-info text-primary" style={{position: 'relative', top: 5, fontSize: 24}}/> New Version Update</ModalHeader>
          <ModalBody>
            There is a new version of app. Please click to update: {packageJson.version}
          </ModalBody>
          <ModalFooter>
            <Button 
            color="primary" 
            className={`btn-shadow btn-multiple-state btn-rounded ps-4 pe-4 me-2 mb-2 ${loading ? "show-spinner" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              setLoading(true)
              localStorage.clear();
              setTimeout(() => {
                emptyCacheStorage();
              }, 2000);
            }}>
              <span className="spinner">
                  <Spinner type="grow" color="light" />
                  <Spinner type="grow" color="light" />
                  <Spinner type="grow" color="light" />
              </span>
              <span className="label">Update Version</span>
            </Button>{' '}
          </ModalFooter>
        </Modal>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { author } = state.authUser;
  const { layoutType, showRightSidebar, leftMenu } = state.layout;
  return { layoutType, showRightSidebar, leftMenu, author };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  startRefreshToken,
  startAcceptFirstLogin
})(withTranslation()(Header));
