import {
    ACTION_AUTHOR,
    LOGIN_AUTHOR_SUCCESS,
    LOGIN_AUTHOR_ERROR,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    CHECK_RESET_TOKEN_SUCCESS,
    CHECK_RESET_TOKEN_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    LOGOUT_AUTHOR,
    FIRST_LOGIN_AUTHOR
} from './types';

const INIT_STATE = {
    author: null,
    forgotAuthorMail: '',
    checkResetToken: '',
    newPassword: '',
    setPassword: '',
    resetPasswordCode: '',
    loading: false,
    error: ''
};


const login = (state = INIT_STATE, action) => {  
    switch (action.type) {
        case ACTION_AUTHOR:
            return { ...state, loading: true, error: '' };
        case LOGIN_AUTHOR_SUCCESS:
            return { ...state, loading: false, author: action.payload, error: '' };
        case LOGIN_AUTHOR_ERROR:
            return { ...state, loading: false, author: null, error: action.payload.message };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotAuthorMail: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotAuthorMail: '', error: action.payload.message };
        case CHECK_RESET_TOKEN_SUCCESS:
            return { ...state, loading: false, checkResetToken: action.payload, error: '' };
        case CHECK_RESET_TOKEN_ERROR:
            return { ...state, loading: false, checkResetToken: '', error: action.payload.message };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, newPassword: action.payload, resetPasswordCode: '', error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, newPassword: '', resetPasswordCode: '', error: action.payload.message };
        case SET_PASSWORD_SUCCESS:
            return { ...state, loading: false, setPassword: action.payload, error: '' };
        case SET_PASSWORD_ERROR:
            return { ...state, loading: false, setPassword: '', error: action.payload.message };
        case REFRESH_TOKEN_SUCCESS:
            let author = { ...state.author }

            author.access_token = action.payload.access_token;
            author.expires_in = action.payload.expires_in;

            return { ...state, author: author, error: '' };
        case REFRESH_TOKEN_ERROR:
            return { ...state, author: null, error: action.payload.message };
        case LOGOUT_AUTHOR:
            return { ...state, author: null, error: '' };
        case FIRST_LOGIN_AUTHOR:
            let firstLogin = { ...state.author }

            firstLogin.user.first_login = 1;

            return { ...state, author: firstLogin };
        default: return state;
    }
}

export default login;
