/**************** AUTH *********************/
export const SET_LOGIN = "SET_LOGIN";

export const ACTION_AUTHOR = "LOGIN_AUTHOR";

export const LOGIN_AUTHOR_SUCCESS = "LOGIN_AUTHOR_SUCCESS";
export const LOGIN_AUTHOR_ERROR = "LOGIN_AUTHOR_ERROR";

export const LOGOUT_AUTHOR = "LOGOUT_AUTHOR";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const CHECK_RESET_TOKEN_SUCCESS = "CHECK_RESET_TOKEN_SUCCESS";
export const CHECK_RESET_TOKEN_ERROR = "CHECK_RESET_TOKEN_ERROR";

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";

export const FIRST_LOGIN_AUTHOR = "FIRST_LOGIN_AUTHOR";